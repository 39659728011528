import React from 'react'
import * as styles from './Amazon.module.css'

const Amazon = () => (
  <span className={styles.amazon}>
  <svg className={styles.svg} viewBox="0 0 276 83" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" fill="none" fillRule="evenodd">
      <g transform="translate(38.000000, 52.000000)">
        <path d="M132.719,12.926 C116.73,24.726 93.551,31 73.59,31 C45.616,31 20.424,20.659 1.361,3.447 C-0.135,2.095 1.199,0.249 2.999,1.296 C23.566,13.264 49.001,20.471 75.27,20.471 C92.991,20.471 112.47,16.794 130.395,9.191 C133.098,8.044 135.364,10.972 132.719,12.926" fill="#EC912D"></path>
      </g>
      <path d="M177.372,57.33 C175.327,54.712 163.855,56.09 158.7,56.707 C157.139,56.895 156.897,55.53 158.304,54.542 C167.457,48.114 182.453,49.97 184.196,52.122 C185.951,54.293 183.734,69.328 175.158,76.503 C173.838,77.606 172.583,77.018 173.169,75.561 C175.099,70.741 179.419,59.95 177.372,57.33" fill="#EC912D"></path>
      <path className={styles.text} d="M159.063,9.131 L159.063,2.885 C159.069,1.933 159.784,1.301 160.647,1.303 L188.643,1.301 C189.538,1.301 190.257,1.952 190.257,2.876 L190.257,8.233 C190.248,9.133 189.491,10.304 188.149,12.166 L173.647,32.87 C179.03,32.744 184.724,33.552 189.615,36.3 C190.718,36.92 191.014,37.837 191.1,38.738 L191.1,45.403 C191.1,46.32 190.095,47.384 189.038,46.83 C180.422,42.316 168.987,41.824 159.459,46.886 C158.485,47.404 157.467,46.356 157.467,45.437 L157.467,39.102 C157.467,38.087 157.486,36.354 158.509,34.809 L175.31,10.705 L160.681,10.704 C159.787,10.704 159.069,10.067 159.063,9.131"></path>
      <path className={styles.text} d="M214.435,9.42 C208.154,9.42 207.758,17.972 207.758,23.308 C207.758,28.643 207.679,40.049 214.358,40.049 C220.957,40.049 221.274,30.85 221.274,25.241 C221.274,21.562 221.115,17.146 220,13.65 C219.047,10.614 217.138,9.42 214.435,9.42 Z M214.358,0.496 C226.998,0.496 233.834,11.351 233.834,25.148 C233.834,38.484 226.284,49.065 214.358,49.065 C201.955,49.065 195.196,38.208 195.196,24.688 C195.196,11.074 202.033,0.496 214.358,0.496 L214.358,0.496 Z"></path>
      <path className={styles.text} d="M145.336,48.034 C144.773,48.54 143.744,48.557 143.324,48.232 C140.414,45.981 138.442,42.557 138.442,42.557 C133.766,47.319 130.455,48.748 124.4,48.748 C117.23,48.748 111.652,44.322 111.652,35.474 C111.652,28.56 115.396,23.858 120.735,21.554 C125.355,19.525 131.81,19.157 136.748,18.602 C136.748,18.602 137.161,13.266 135.714,11.32 C134.593,9.812 132.685,9.107 130.934,9.107 C127.774,9.094 124.722,10.813 124.116,14.118 C123.943,15.063 123.245,15.857 122.51,15.778 L114.354,14.894 C113.729,14.822 112.881,14.178 113.082,13.113 C114.979,3.098 124.022,0.073 132.128,0.073 C136.271,0.073 141.689,1.178 144.956,4.314 C149.098,8.185 148.701,13.349 148.701,18.974 L148.701,32.24 C148.701,36.233 150.358,37.983 151.914,40.133 C152.457,40.91 152.581,41.83 151.888,42.397 C150.147,43.856 145.336,48.034 145.336,48.034 Z M136.748,27.269 C136.748,30.589 136.829,33.352 135.156,36.304 C133.802,38.699 131.65,40.175 129.26,40.175 C125.994,40.175 124.081,37.685 124.081,33.997 C124.081,26.744 130.589,25.426 136.748,25.426 L136.748,27.269 L136.748,27.269 Z"></path>
      <path className={styles.text} d="M33.683,48.034 C33.121,48.54 32.091,48.557 31.672,48.232 C28.761,45.981 26.79,42.557 26.79,42.557 C22.113,47.319 18.803,48.748 12.748,48.748 C5.577,48.748 0,44.322 0,35.474 C0,28.56 3.744,23.858 9.083,21.554 C13.703,19.525 20.158,19.157 25.096,18.602 C25.096,18.602 25.508,13.266 24.062,11.32 C22.94,9.812 21.033,9.107 19.281,9.107 C16.122,9.094 13.069,10.813 12.464,14.118 C12.29,15.063 11.593,15.857 10.858,15.778 L2.701,14.894 C2.076,14.822 1.228,14.178 1.429,13.113 C3.326,3.098 12.369,0.073 20.476,0.073 C24.619,0.073 30.037,1.178 33.304,4.314 C37.445,8.185 37.048,13.349 37.048,18.974 L37.048,32.24 C37.048,36.233 38.706,37.983 40.262,40.133 C40.805,40.91 40.929,41.83 40.235,42.397 C38.495,43.856 33.683,48.034 33.683,48.034 Z M25.096,27.269 C25.096,30.589 25.176,33.352 23.503,36.304 C22.15,38.699 19.997,40.175 17.607,40.175 C14.341,40.175 12.428,37.685 12.428,33.997 C12.428,26.744 18.937,25.426 25.096,25.426 L25.096,27.269 L25.096,27.269 Z"></path>
      <path className={styles.text} d="M58.59,23.399 C58.59,18.34 58.351,11.351 64.474,11.351 C70.515,11.351 69.72,18.525 69.72,23.399 L69.729,46.574 C69.729,47.412 70.402,48.089 71.258,48.143 L79.765,48.143 C80.673,48.143 81.407,47.443 81.407,46.571 L81.407,23.399 C81.407,20.915 81.328,17.238 82.202,15.029 C83.077,12.821 85.224,11.441 87.292,11.441 C89.755,11.441 91.663,12.27 92.299,15.214 C92.697,16.962 92.538,21.562 92.538,23.399 L92.541,46.574 C92.541,47.412 93.213,48.089 94.069,48.143 L102.575,48.143 C103.484,48.143 104.218,47.443 104.218,46.571 L104.226,18.985 C104.226,14.293 104.781,8.959 102.078,5.279 C99.693,1.967 95.798,0.496 92.22,0.496 C87.212,0.496 82.521,3.072 80.453,8.591 C78.068,3.072 74.729,0.496 69.402,0.496 C64.156,0.496 60.26,3.072 58.194,8.591 L58.033,8.591 L58.033,3.009 C58.033,2.13 57.321,1.418 56.442,1.418 L48.495,1.418 C47.616,1.418 46.904,2.13 46.904,3.009 L46.904,46.552 C46.904,47.431 47.616,48.143 48.495,48.143 L56.999,48.143 C57.878,48.143 58.59,47.431 58.59,46.552 L58.59,23.399"></path>
      <path className={styles.text} d="M251.891,46.552 C251.891,47.431 251.179,48.143 250.3,48.143 L241.795,48.143 C240.917,48.143 240.204,47.431 240.204,46.552 L240.204,3.009 C240.204,2.13 240.917,1.418 241.795,1.418 L249.659,1.418 C250.58,1.418 251.25,2.159 251.25,2.661 L251.25,9.326 L251.414,9.326 C253.8,3.346 257.137,0.496 263.02,0.496 C266.837,0.496 270.574,1.875 272.959,5.648 C275.185,9.142 275.185,15.029 275.185,19.261 L275.185,46.768 C275.09,47.542 274.394,48.143 273.552,48.143 L265.015,48.143 C264.226,48.093 263.59,47.513 263.499,46.768 L263.513,20.355 C263.513,11.518 260.127,11.258 258.173,11.258 C255.9,11.258 254.072,13.033 253.272,14.689 C252.08,17.358 251.89,20.088 251.89,23.034 L251.891,46.552"></path>
    </g>
  </svg>
  </span>
);

export default Amazon;